import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'playInstantWin', pathMatch: 'full' },
  { path: 'animazione', loadChildren: () => import('./animazione/animazione.module').then(m => m.AnimazioneModule) },
  { path: 'playInstantWin', loadChildren: () => import('./play-instant-win/play-instant-win.module').then(m => m.PlayInstantWinModule),  },
  { path: 'email-info', loadChildren: () => import('./email-info/email-info.module').then(m => m.EmailInfoModule) },
  { path: '**', redirectTo: 'playInstantWin' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
