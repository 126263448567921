import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ErrorDialogService } from "../common/error-dialog/errordialog.service";
import { ModalComponent } from "../common/modal/modal.component";


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public errorDialogService: ErrorDialogService, public matDialog: MatDialog,) { }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('interceptor ' + sessionStorage.getItem('access_token'));
        // if (!environment.production) {
        //if(request.url === 'https://test.api.lmscloud.it/api/acel_iw_bolletta/send-victory-email'){
            const token: string = sessionStorage.getItem('access_token');
            /* per ora non serve da togliere il commento */
            if (token) {
                // console.log('ho il token lo inserisco nell\'headers');
                request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
            }

            if (!request.headers.has('Content-Type')) {
                // console.log('ho il Content-Type');
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }

            request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
            // console.log('REQUEST = ');
            // console.log(request);
        //}
            // }
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // console.log('sono qui errore');
                        // console.log('event--->>>', event);
                        // this.errorDialogService.openDialog(event);
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    // console.log('ERRORE = ');
                    // console.log(error);
                    let data = {};
                    data = {
                        status: error.status ? error.status : '',
                        message : error.error && error.error.error? error.error.error  : ''
                    };
                    // console.log('error.error.error = ');
                    // console.log(error.error.error);
                    if(error.error === 'Instant Win Stock not found') {
                        this.openModalSorry();
                    }
                    else 
                    {
                        if (error.error && error.error.error === 'invalid_grant') {
                            data = {
                                status: error.status ? error.status : '',
                                message : 'Per un problema di connessione, non è stato possibile avviare il gioco. Non preoccuparti! Torna sul link di partecipazione e riprova'
                            };
                        }

                        this.errorDialogService.openDialog(data);
                    }
                    
                    return throwError(error);
                })
            );
        
        
    }

    openModalSorry() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-component';
        // dialogConfig.height = '350px';
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'myapp-no-padding-dialog';
        dialogConfig.data = {
          action: 'goToNewRoute',
          title: 'Non hai vinto...',
          message: 'Guadagna altre Energy Ball e ritenta la fortuna!',
          actionButtonText: 'Torna indietro',
          newRoute: '/dashboard'
        };
        const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
      }
}
