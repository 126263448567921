
<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img 
    width= "auto"
    height="70px"  
    alt=""
    src="../assets/images/company_logo.png"
  />
    
</div>

<div class="">
  <router-outlet></router-outlet>
</div>
