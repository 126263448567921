import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ModalActionsService {

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document) {
  }

  modalAction(modalData: any) {
    switch (modalData.action) {
      case 'goToNewRoute':
        this.goToNewRoute(modalData);
        break;
      case 'reload':
          this.document.defaultView.location.reload();
          break;
      default:
        break;
    }
  }

  private goToNewRoute(modalData: any) {
    this.router.navigateByUrl(modalData.newRoute);
  }
}
