import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, BASE_PATH } from 'lms-api';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { environment } from 'src/environments/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MyMaterialModule } from './material.module';
import { SharedModule } from './shared/shared.module';
// import { BASE_URL } from './app.tokens';
import { ErrorDialogComponent } from './common/error-dialog/errordialog.component';
import { ErrorDialogService } from './common/error-dialog/errordialog.service';
@NgModule({
  declarations: [
    AppComponent,
    ErrorDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule,
    HttpClientModule,
    MyMaterialModule,
    SharedModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.lmsApiBasePath +'/api'],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    ApiModule,
    ErrorDialogService,
    { provide: BASE_PATH, useValue: environment.lmsApiBasePath },
    //{ provide: BASE_URL, useValue: environment.lmsApiBasePath },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
