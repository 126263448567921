<div id="modal-content-wrapper">
  <header id="modal-header">
      <h1 id="modal-title">{{ modalData.title }} 
      <br/> {{ modalData.present }}</h1>
  </header>
  <section id="modal-body">
    <h3 class="text-center">{{ modalData.message }}</h3>
  </section>
  <footer id="modal-footer">
    <a class="cancel-button" (click)="closeModal()">
      {{ modalData.cancelButtonText }}
    </a>
    <a (click)="actionFunction()">
      {{ modalData.actionButtonText }}
    </a>
    
    
  </footer>
</div>
